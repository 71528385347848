export default {
  pages: {
    header: {
      menu_1: '主页',
      menu_2: '关于我们',
      subMenu_2a: '公司概览',
      subMenu_2b: '公司架构',
      subMenu_2c: '里程碑',
      menu_3: '公司简介',
      subMenu_3a: '产业布局',
      subMenu_3b: '产品介紹',
      menu_4: '投资者关系',
      subMenu_4a: '财务资料',
      subMenu_4b: '业绩演示资料',
      subMenu_4c: '公司公告',
      menu_5: '新闻中心',
      subMenu_5a: '公司动态',
      menu_6: '中 / 英',
      subMenu_6a: 'English',
      subMenu_6b: '简体中文',
    },

    about: {
      title: '公司概览',
      companyInfo: {
        item1: '中国三江精细化工有限公司',
        item2: '（「',
        item3: '中国三江化工',
        item4: '」，股份代号：2198.HK）于2010年在香港联合交易所上市，为中国最大的民营',
        item5: '环氧乙烷（',
        item6: 'EO',
        item7: '）及',
        item8: 'AEO',
        item9: '表面活化剂',
        item10: '生产商和供货商。目前已形成以',
        item11: 'EO',
        item12: '、乙二醇（',
        item13: 'EG',
        item14: '）及聚丙烯（',
        item15: 'PP',
        item16: '）为核心，',
        item17: 'AEO',
        item18: '表面活化剂、甲基叔丁基醚（',
        item19: 'MTBE',
        item20: '）、碳五（',
        item21: 'C5',
        item22: '）',
        item23: '为辅的多元化产品组合，成为具备平衡风险及抗周期能力的垂直整合化工集团。',
      },
    },

    achieve: {
      title: '业绩演示资料',
      interimResults: '中期业绩',
      annualPerformance: '全年业绩',
    },

    active: {
      title: '公司动态',
      news2021: {
        newsDate_1: '2021年06月07日',
        newsTitle_1: '三江化工新福利 | 新大巴车投入使用 美味净菜打包回家',
        newsDate_2: '2021年06月07日',
        newsTitle_2:
          '好消息！| 三江嘉化集团合唱队代表化工园区在庆祝中国共产党建党100周年嘉兴港区群众性合唱大赛中喜获金奖！',
        newsDate_3: '2021年06月07日',
        newsTitle_3: '安全生产月专刊 | 安全生产十大法则、安全环保十大零容忍',
        newsDate_4: '2021年05月27日',
        newsTitle_4: '余热发电 综合利用 | 三江化工年产100万吨EO/EG项目配套工程余热发电项目顺利开工',
        newsDate_5: '2021年05月27日',
        newsTitle_5: '三江化工生产装置基础知识培训纪实',
        newsDate_6: '2021年05月12日',
        newsTitle_6: '嘉兴市委常委、军分区政委于昕一行莅临三江化工调研和服务企业',
        newsDate_7: '2021年04月30日',
        newsTitle_7: '嘉兴市“互学互比互赛”活动走进三江化工100万吨/年EO/EG项目',
        newsDate_8: '2021年04月30日',
        newsTitle_8: '三江化工工会举行庆祝“五一”劳动节暨2020年度工会优秀积极分子表彰',
        newsDate_9: '2021年04月30日',
        newsTitle_9: '致敬劳动者奋进新时代丨向奋战在一线的全体员工致以节日祝贺',
        newsDate_10: '2021年04月30日',
        newsTitle_10: '跟党走 · 庆祝建党100周年 │ 兴兴美福支部做好榜样、勇于担当、知行合一',
        newsDate_11: '2021年04月28日',
        newsTitle_11: '思考 提升 突破 融入 | 三江化工召开一季度工作会议暨一季度党委工作会议',
        newsDate_12: '2021年04月28日',
        newsTitle_12: '三江化工 | 一体化强链补链',
        newsDate_13: '2021年04月28日',
        newsTitle_13: '学党史 悟思想 出实力 当先锋 | 三江党支部组织党史学习活动',
        newsDate_14: '2021年04月19日',
        newsTitle_14: '树榜样 做先锋 | 制氧厂运行二班获嘉兴港区“工人先锋号”荣誉称号',
        newsDate_15: '2021年04月19日',
        newsTitle_15: '心的凝聚 力的角逐 | 三江化工第十四届职工拔河比赛',
        newsDate_16: '2021年04月19日',
        newsTitle_16: '中国疾控中心：新冠病毒疫苗接种问答',
        newsDate_17: '2021年03月08日',
        newsTitle_17: '约会春天 | 致最亲爱的你们三八妇女节快乐',
        newsDate_18: '2021年03月02日',
        newsTitle_18: '嘉兴市委常委、常务副市长陈利众等领导一行莅临公司调研',
        newsDate_19: '2021年02月26日',
        newsTitle_19: '花灯吐艳闹元宵 趣味灯谜庆佳节 | 三江化工祝您元宵节快乐',
        newsDate_20: '2021年02月26日',
        newsTitle_20: '签署战略合作协议 | 民生银行杭州分行丛军行长莅临公司调研',
        newsDate_21: '2021年02月20日',
        newsTitle_21: '热烈祝贺公司董事长韩建红女士获得2020年度优秀企业家称号',
        newsDate_22: '2021年02月11日',
        newsTitle_22: '三江化工祝全体员工新春快乐，身体健康！',
        newsDate_23: '2021年02月10日',
        newsTitle_23: '最美三江人 向劳动者致敬 | 三江化工召开2020年度表彰大会',
        newsDate_24: '2021年02月10日',
        newsTitle_24: '慰问困难职工 传递工会温暖 | 工会开展困难职工座谈会',
        newsDate_25: '2021年02月10日',
        newsTitle_25: '节前送慰问 暖心过春节 | 党支部走访慰问结对困难群众',
        newsDate_26: '2021年01月29日',
        newsTitle_26: '小食堂大温暖 人气爆棚齐夸赞 | 三江食堂改造顺利完成并将于2月1日试营业',
        newsDate_27: '2021年01月26日',
        newsTitle_27: '嘉兴港区管委会主任冯中海等领导一行莅临公司开展“三服务”活动',
        newsDate_28: '2021年01月12日',
        newsTitle_28: '三江化工 兴兴新能源｜2021年安全环保责任书签约',
        newsDate_29: '2021年01月05日',
        newsTitle_29: '实干笃行 接续奋斗 做2021答卷人 | 三江化工隆重举行2021年方针目标签约仪式',
      },
      news2020: {
        newsDate_1: '2020年12月16日',
        newsTitle_1: '三江嘉化集团党委2020年四季度党委会议｜重点学习十九届五中全会会议精神',
        newsDate_2: '2020年12月16日',
        newsTitle_2: '垃圾分类，从我做起',
        newsDate_3: '2020年12月04日',
        newsTitle_3: '各事业部召开2021-2023年工作思路及规划会议',
        newsDate_4: '2020年12月04日',
        newsTitle_4: '防范电信诈骗 打击网络犯罪',
        newsDate_5: '2020年11月25日',
        newsTitle_5:
          '安全检查，我们在行动，吸取衢州11.9事件教训 | 公司董事长带队对三江浩嘉进行安全大检查',
        newsDate_6: '2020年11月25日',
        newsTitle_6: '共创美好生活 | 三江化工举办《民法典》专题培训',
        newsDate_7: '2020年11月25日',
        newsTitle_7: '健康365 | 健康生活方式从分餐开始',
        newsDate_8: '2020年11月17日',
        newsTitle_8: '热烈祝贺公司员工在省级职工职业技能竞赛中取得好成绩',
        newsDate_9: '2020年11月17日',
        newsTitle_9: '工信部领导一行莅临公司调研和指导工作',
        newsDate_10: '2020年11月17日',
        newsTitle_10: '红船党课学习纪实 | 开启全面建设社会主义现代化国家新征程',
        newsDate_11: '2020年10月28日',
        newsTitle_11: '推进管理创新！|公司召开三季度经济工作会议',
        newsDate_12: '2020年10月28日',
        newsTitle_12: '防范火灾风险 严查消防隐患',
        newsDate_13: '2020年10月28日',
        newsTitle_13: '疫情未结束 防护勿松懈',
        newsDate_14: '2020年09月30日',
        newsTitle_14: '三江化工祝全体员工中秋国庆双节快乐！',
        newsDate_15: '2020年09月30日',
        newsTitle_15: '走访慰问困难户 点滴真情暖人心 | 三江化工党支部中秋国庆慰问结对困难户',
        newsDate_16: '2020年09月30日',
        newsTitle_16: '安全不松劲 警钟要长鸣 | 三江嘉化集团安全环保节前大检查',
        newsDate_17: '2020年09月28日',
        newsTitle_17: '乘风破浪 梦想终将可期！| 三江嘉化举行迎中秋•庆国庆新入职大学生联欢会',
        newsDate_18: '2020年09月18日',
        newsTitle_18: '三江高分子聚醚项目暨美国AP供气协议签约仪式隆重举行！',
        newsDate_19: '2020年08月21日',
        newsTitle_19: '送清凉 助生产丨公司领导慰问高温期间奋战在生产一线的员工',
        newsDate_20: '2020年08月21日',
        newsTitle_20: '坚守工作不离岗，抓好质量不放松',
        newsDate_21: '2020年08月21日',
        newsTitle_21: '守初心担使命，找差距抓落实',
        newsDate_22: '2020年07月22日',
        newsTitle_22: '三江兴兴党支部主题党日活动 | 南湖行 探寻力量之源',
        newsDate_23: '2020年07月22日',
        newsTitle_23a: '荆楚计划',
        newsTitle_23b: '首批大学生正式报到啦！',
        newsDate_24: '2020年07月22日',
        newsTitle_24: '积极参加学习强国竞赛，努力提升学习强国水平',
        newsDate_25: '2020年07月10日',
        newsTitle_25:
          '热烈祝贺三江嘉化集团董事局主席管建忠荣获嘉兴好人和战“疫”红船先锋共产党员等荣誉称号！',
        newsDate_26: '2020年07月10日',
        newsTitle_26: '永远跟党走！ | 三江嘉化召开庆祝中国共产党成立99周年暨党建工作座谈会',
        newsDate_27: '2020年07月10日',
        newsTitle_27: '节能减排 绿色发展 丨 三江化工事业部开展节能宣传周活动',
        newsDate_28: '2020年06月30日',
        newsTitle_28: '热烈庆祝中国共产党成立99周年！',
        newsDate_29: '2020年06月30日',
        newsTitle_29: '安全生产月丨坚持实学实际实用原则，强化岗位安全技能培训',
        newsDate_30: '2020年06月30日',
        newsTitle_30: '祝贺三江嘉化党委书记管建忠荣获全国石油和化工行业疫情防控工作先进个人荣誉称号！',
        newsDate_31: '2020年06月30日',
        newsTitle_31: '安全生产月丨坚持实学实际实用原则，强化岗位安全技能培训',
        newsDate_32: '2020年06月24日',
        newsTitle_32: '嘉兴港区管委会主任冯中海一行莅临三江嘉化调研和服务企业',
        newsDate_33: '2020年06月24日',
        newsTitle_33: '安全生产月 | 开展安全培训，提高安全意识',
        newsDate_34: '2020年06月19日',
        newsTitle_34: '三江·嘉化与嘉兴学院签署共建未来技术学院合作框架协议',
        newsDate_35: '2020年06月19日',
        newsTitle_35: '安全生产月丨严查安全隐患，夯实安全责任',
        newsDate_36: '2020年06月19日',
        newsTitle_36: '应急科普 | 学会危化品泄露应急处置 掌握自救方法',
        newsDate_37: '2020年06月09日',
        newsTitle_37: '2020安全生产月',
        newsDate_38: '2020年06月09日',
        newsTitle_38: '2020年“三江·嘉化”消防化救应急技能比武',
        newsDate_39: '2020年06月09日',
        newsTitle_39: '应急器材使用：灭火器、消防栓、正压式呼吸器，全员必看！',
        newsDate_40: '2020年04月30日',
        newsTitle_40: '热烈祝贺公司总经理管建忠荣获2020年嘉兴市五一劳动奖章等荣誉',
        newsDate_41: '2020年04月30日',
        newsTitle_41: '热烈庆祝“五一”国际劳动节 | 向奋战在一线的全体员工致以节日祝贺',
        newsDate_42: '2020年04月30日',
        newsTitle_42: '三江嘉化集团积极开展学习嘉兴市委书记张兵港区调研讲话精神',
        newsDate_43: '2020年04月23日',
        newsTitle_43: '只争朝夕抓落实，不负厚望勇争先！嘉兴市委书记张兵一行莅临三江嘉化调研指导',
        newsDate_44: '2020年04月15日',
        newsTitle_44: '“点对点”云视频 | 三江嘉化首次网络面试火热进行中',
        newsDate_45: '2020年04月02日',
        newsTitle_45: '荆楚计划“职”等你来 | 三江嘉化武汉定向“云”招聘启幕',
        newsDate_46: '2020年03月24日',
        newsTitle_46: '湖北员工一个都不能少，三江嘉化接你们返工！',
        newsDate_47: '2020年03月20日',
        newsTitle_47: '热烈祝贺我公司总经理管建忠先生荣获嘉兴市“功勋企业家”的荣誉',
        newsDate_48: '2020年03月20日',
        newsTitle_48: '关键时刻不松劲 坚持做好输入性疫情防控工作',
        newsDate_49: '2020年03月20日',
        newsTitle_49: '祝贺浙江三江化工新材料有限公司获评浙江省亩均效益领跑企业石化20强。',
        newsDate_50: '2020年03月13日',
        newsTitle_50: '嘉兴港区党工委书记戴锋一行来我公司开展“三服务”活动',
        newsDate_51: '2020年03月10日',
        newsTitle_51: '嘉兴市经信局卓卫明主任一行莅临公司调研和服务企业',
        newsDate_52: '2020年02月21日',
        newsTitle_52: '防控疫情 返岗稳产两不误',
        newsDate_53: '2020年02月03日',
        newsTitle_53: '疫情防控环节一个也不能放过——管总督察公司疫情防控工作',
        newsDate_54: '2020年02月03日',
        newsTitle_54: '新型冠状病毒预防手册',
        newsDate_55: '2020年01月23日',
        newsTitle_55: '董事局主席给您发来一个祝福锦囊',
        newsDate_56: '2020年01月21日',
        newsTitle_56: '三江化工有限公司工会春节慰问',
        newsDate_57: '2020年01月13日',
        newsTitle_57: '我司荣获2019年度中国石油化工行业企业公民楷模荣誉',
        newsDate_58: '2020年01月01日',
        newsTitle_58: '责任在肩 使命在心——三江嘉化2020年方针目标签订仪式隆重举行',
        newsDate_59: '2020年01月01日',
        newsTitle_59: '三江化工祝全体员工元旦快乐！',
      },
      news2019: {
        newsDate_1: '2019年12月24日',
        newsTitle_1: '热烈祝贺三江化工与嘉兴税务局签订《税收遵从合作协议》',
        newsDate_2: '2019年12月18日',
        newsTitle_2: '冬日暖心--集团党委召开民主生活会',
        newsDate_3: '2019年11月26日',
        newsTitle_3: '勇敢者的挑战 马拉松赛场上有你有我',
        newsDate_4: '2019年10月29日',
        newsTitle_4: '三江嘉化集团召开三季度工作会议',
        newsDate_5: '2019年10月17日',
        newsTitle_5: '三江化工响应“社会扶贫进企业、进单位”活动',
        newsDate_6: '2019年09月30日',
        newsTitle_6: '三江嘉化集团党委成立大会隆重召开',
        newsDate_7: '2019年09月30日',
        newsTitle_7: '迎70周年大庆保平安 安全环保一刻不放松',
        newsDate_8: '2019年03月14日',
        newsTitle_8: '三江化工工会开展“展新风采，做新女性”三八节系列活动',
        newsDate_9: '2019年03月14日',
        newsTitle_9: '三江化工党支部开展“植树节”主题党日活动',
        newsDate_10: '2019年02月19日',
        newsTitle_10: '开局就要奔跑 荣誉即是动力',
        newsDate_11: '2019年02月19日',
        newsTitle_11: '三江化工有限公司工会积极开展2019年“两节”帮困送温暖活动',
        newsDate_12: '2019年02月02日',
        newsTitle_12: '祝贺三江嘉化获2018感动港区最佳企业集群等奖项',
      },
      news2018: {
        newsDate_1: '2018年12月29日',
        newsTitle_1: '嘉兴市政协领导莅临公司指导工作',
        newsDate_2: '2018年12月14日',
        newsTitle_2:
          '热烈祝贺公司董事局主席管建忠先生荣获 中国石油和化学工业改革开放40周年突出贡献人物！',
        newsDate_3: '2018年09月30日',
        newsTitle_3: '管主席带队国庆安全生产大检查',
      },
    },

    first: {
      companyAnnouncement: {
        title: '公司公告',
        announceTitle_1: '股份发行的证券变动月报表',
        announceDate_1: '2021年10月04日',
        announceTitle_2: '2021中期报告',
        announceDate_2: '2021年09月09日',
        announceTitle_3: '股份发行人的证券变动月报表',
        announceDate_3: '2021年08月31日',
        announceTitle_4a: '截至2021年6月30日止六个月的',
        announceTitle_4b: '中期业绩公告',
        announceDate_4: '2021年08月26日',
        announceTitle_5: '董事名单和他们的地位和作用',
        announceDate_5: '2021年08月26日',
      },
      contactInfo: {
        title: '聯絡三江化工',
        hkAddress: '香港地址：香港上环 德辅道中199号 无限极广场1702室',
        cnAddress: '中国（大陆）地址：浙江嘉兴港区平海路西侧（314201）',
        investorRelationsContact: '投资者关系联络',
        contactPerson_1: '陈娴',
        contactEmail_1: 'chenx@jxsjchem.com',
        contactNo_1: '(+86) 57385286836',
        contactPerson_2: '叶毅恒',
        contactEmail_2: 'henryyip@jxsjchem.com',
        contactNo_2: '(+852) 28510255',
      },
    },

    framework: {
      title: '股权结构',
      directorResume: {
        title: '董事履历',
        director1a: '韩建红',
        director1b:
          '自二零一零年三月二十二日起出任执行董事。韩女士亦为本集团的创办人之一。彼主要负责本集团业务规划、业务架构及重组，并监督本集团的法律事宜及投资者关系。韩女士亦担任本集团旗下数家附属公司的董事，于化工业积逾20年经验。韩女士为执行董事管建忠先生的配偶及执行董事韩建平先生的胞妹。',
        director2a: '韩建平',
        director2b: '自二零一零年八月二十四日起出任执行董事。彼主要负责本集团的销售工作。',
        director2c:
          '韩先生亦担任本集团若干附属公司的董事，于化工业积逾25年经验。韩先生为执行董事韩建红女士的胞兄及执行董事管建忠先生的内兄。韩先生于一九九八年加入本集团。',
        director3a: '饶火涛',
        director3b:
          '于二零一七年三月十五日任命为执行董事。彼主要負責本集團的專案管理。饶先生于一九九六年毕业于武汉工程大学，获化学工艺学士学位，并于二零零八年毕业于浙江大学，获化学工程硕士学位。饶先生于化工制造业拥有逾22年的经验，并于二零一零年加入本集团。',
        director4a: '沈凯军',
        director4b:
          '自二零一零年八月二十四日起出任独立非执行董事。彼为本公司审核委员会主席及提名委员会的成员。沈先生于一九九五年毕业于杭州电子工业学院获会计学士学位，并于一九九三年十二月获接纳为中国注册会计师。沈先生于一九九七年七月进一步获颁注册认证会计师资格，可从事证券相关业务，后于二零零零年六月获颁注册税务代理资格。沈先生于会计及企业管理拥有逾25年经验。',
        director5a: '裴愚',
        director5b:
          '自二零一四年六月三十日起出任独立非执行董事。彼为审核委员会、薪酬委员会及提名委员会成员。裴女士于一九九二年六月毕业自北京师范大学，取得文学学士学位，并于一九九七年六月取得中国政法大学法律系双学位。裴女士于中国法律界拥有逾17年经验。',
        director6a: '孔良',
        director6b:
          '自二零一六年七月二十五日起出任独立非执行董事。彼为审核委员会及薪酬委员会成员。孔先生于一九八八年毕业于复旦大学，获管理科学学士学位，于一九九一年毕业于对外经济贸易大学，获经济学硕士学位，于一九九五年毕业于奈尔洛德商业大学，获工商管理硕士学位，且于二零一一年毕业于北京大学，获教育经济管理博士学位。孔先生于教授工商管理高等教育方面拥有逾17年的经验。',
      },
      seniorManagementResume: {
        title: '高级管理人员履历',
        senior1a: '陈娴',
        senior1b:
          '本集团董事会秘书兼总会计师。陈女士主要负责本集团的财务管理。陈女士于一九九六年毕业于浙江工商大学杭州商学院，获会计文凭，并于二零一三年获中国人民大学会计本科学位。彼于二零零九年加入本集团。',
        senior2a: '丁嵘国',
        senior2b:
          '本集团生产总监。丁先生主要负责本集团的生产管理以及安全保障及环境保护。彼于二零零四年加入本集团。',
      },
    },

    industry: {
      title: '产业布局',
    },

    mileage: {
      title: '里程碑',
    },

    money: {
      title: '财务资料',
      currencyRMB: '（人民币千元）',
      year1: '2019中期',
      year2: '2018年',
      year3: '2017年',
      year4: '2016年',
      year5: '2015年',
      moneyInfo_1: {
        title: '收益',
        year1: '4,190,077',
        year2: '9,585,859',
        year3: '8,897,143',
        year4: '6,647,019',
        year5: '4,966,427',
      },
      moneyInfo_2: {
        title: '毛利',
        year1: '206,338',
        year2: '996,124',
        year3: '1,085,275',
        year4: '906,570',
        year5: '164,449',
      },
      moneyInfo_3: {
        title: '股东应占纯利',
        year1: '32,275',
        year2: '398,915',
        year3: '690,793',
        year4: '552,614',
        year5: '（145,502）',
      },
      moneyInfo_4: {
        title: '每股盈利-基本（人民币，分）',
        year1: '3.15',
        year2: '38.89',
        year3: '67.30',
        year4: '56.09',
        year5: '（14.79）',
      },
      moneyInfo_5: {
        title: '每股末期股息（港仙）',
        year1: '-',
        year2: '10.0',
        year3: '12.5',
        year4: '11.5',
        year5: '-',
      },
      moneyInfo_6: {
        title: '每股中期股息（港仙）',
        year1: '5.0',
        year2: '10.0',
        year3: '10.5',
        year4: '-',
        year5: '-',
      },
      moneyInfo_7: {
        title: '股息派付率（基于年内派付总额）',
        year1: '-',
        year2: '50.0%',
        year3: '30.0%',
        year4: '18.4%',
        year5: '-',
      },
      moneyInfo_8: {
        title: '毛利率',
        year1: '4.9%',
        year2: '10.4%',
        year3: '12.2%',
        year4: '13.6%',
        year5: '3.3%',
      },
      moneyInfo_9: {
        title: '资产负债比率-以计息借贷对资产总值为基准',
        year1: '35.2%',
        year2: '32.6%',
        year3: '27.1%',
        year4: '42.9%',
        year5: '50.9%',
      },
    },

    notice: {
      topTitle: '置頂公告',
      topItem1: '發佈企業通訊',
      title: '公司公告',
      announceTitle_1: '股份发行的证券变动月报表',
      announceDate_1: '2021年10月04日',
      announceTitle_2: '2021中期报告',
      announceDate_2: '2021年09月09日',
      announceTitle_3: '股份发行人的证券变动月报表',
      announceDate_3: '2021年08月31日',
      announceTitle_4: '截至2021年6月30日止六个月的 中期业绩公告',
      announceDate_4: '2021年08月26日',
      announceTitle_5: '董事名单和他们的地位和作用',
      announceDate_5: '2021年08月26日',
      announceTitle_6: '委任执行董事 及 董事会组成变更',
      announceDate_6: '2021年08月26日',
      announceTitle_7: '董事会召开通告',
      announceDate_7: '2021年08月10日',
      announceTitle_8: '股份发行人的证券变动月报表',
      announceDate_8: '2021年08月01日',
      announceTitle_9: '股份发行人的证券变动月报表',
      announceDate_9: '2021年06月30日',
      announceTitle_10: '股份发行人的证券变动月报表',
      announceDate_10: '2021年06月01日',
    },

    product: {
      title: '产品介绍',
    },
  },
};
